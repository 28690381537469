import {
  demandForecastUpdate,
  demandOutturnUpdate,
  indicatedForecastsUpdate,
  temperatureUpdate,
  surplusAndMarginUpdate,
  systemFrequencyUpdate,
  windGenUpdate,
  iteration2VideoUpdate,
  nonBmStorUpdate,
  bmuPhysicalUpdate,
  accountsUpdate,
  disbsadUpdate,
  marketViewUpdate,
  apiDocsUpdate,
  irisUpdate,
  detailedSystemPricesUpdate,
  systemSellBuyPricesUpdate,
  derivedDataUpdate,
  lolpdrmUpdate,
  remitUpdate,
  creditDefaultNoticeUpdate,
  sosoUpdate,
  totalExemptVolumeUpdate,
  summaryPageUpdate,
} from "assets/updatesData/updates";
import { Update, UpdateWithImage } from "models/updateModel";

/*
PLEASE READ THE LINK BELOW BEFORE ADDING UPDATES:
https://elxnfa.visualstudio.com/Insights%20Solution/_wiki/wikis/Insights-Solution.wiki/11056/Adding-What's-New-content-on-the-page
 */

/**
 * This is the featured update displayed at the top of on the "What's new" page.
 */
export const featuredUpdateData: UpdateWithImage = summaryPageUpdate;

/**
 * These are the updates displayed underneath the featured update on the "What's
 * new" page.
 */
export const otherUpdatesData: [
  UpdateWithImage,
  UpdateWithImage,
  UpdateWithImage
] = [totalExemptVolumeUpdate, derivedDataUpdate, detailedSystemPricesUpdate];

/**
 * These are the updates displayed at the bottom of the "What's new" page, without
 * images. They are in reverse chronological order (most recent first).
 */
export const previousUpdatesData: Update[] = [
  systemSellBuyPricesUpdate,
  sosoUpdate,
  creditDefaultNoticeUpdate,
  remitUpdate,
  lolpdrmUpdate,
  marketViewUpdate,
  apiDocsUpdate,
  bmuPhysicalUpdate,
  irisUpdate,
  accountsUpdate,
  disbsadUpdate,
  nonBmStorUpdate,
  windGenUpdate,
  demandForecastUpdate,
  systemFrequencyUpdate,
  indicatedForecastsUpdate,
  demandOutturnUpdate,
  surplusAndMarginUpdate,
  temperatureUpdate,
  iteration2VideoUpdate,
];

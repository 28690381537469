import { StyledAnchor } from "components/components/TextLink/style";
import styled from "styled-components";
import colours from "styles/colours";
import fonts from "styles/fonts";

export const StyledTitle = styled.h2`
  &.no-wrap {
    white-space: nowrap;
  }
`;

export const AdditionalInfo = styled.div`
  ${StyledTitle} {
    font-size: ${fonts.mediumLarge};
    margin-bottom: 1.5rem;
  }
`;

export const StyledInfo = styled.span`
  display: block;
`;

export const ViewAllApiDocumentationButton = styled(StyledAnchor)`
  && {
    background: transparent;
    color: ${colours.linkColor};
    border: 1px solid ${colours.linkColor};
    font-weight: ${fonts.semibold};
    letter-spacing: unset;
    padding: 0.6rem 1.9rem;
    border-radius: 200px;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const ContainerDiv = styled.div`
  .columns {
    gap: 3rem;
  }

  .no-grow {
    flex-grow: 0;
  }
`;

export const SingleRowContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem 0;
`;

export const Link = styled.a`
  font-size: ${fonts.small};
  text-decoration: underline;
  color: inherit;
  flex: 0 0 auto;
  margin-top: 0.5rem;
`;

import FullWidthSection from "components/components/FullWidthSection/FullWidthSection";
import SwaggerSingleRow from "components/dataVisualization/dataPageComponents/SwaggerSingleRow/SwaggerSingleRow";
import { useSwaggerFile } from "contexts/SwaggerFileContext";
import React from "react";
import {
  getEndpointDataByPath,
  transformSwaggerJsonFile,
} from "utils/apiDocumentationUtils";
import routeConstants from "utils/routeConstants";

import {
  AdditionalInfo,
  ContainerDiv,
  Link,
  SingleRowContainer,
  StyledInfo,
  StyledTitle,
  ViewAllApiDocumentationButton,
} from "./style";

export interface DocumentationInformation {
  chart?: string[];
  tile?: string[];
  table?: string[];
}

interface Props {
  documentationInformation: DocumentationInformation;
  isFullWidth?: boolean;
  source?: ExternalDataSource;
}

export enum ExternalDataSource {
  NESO = "National Energy System Operator",
  Elexon = "Elexon",
}

const AdditionalInfoSection: React.FC<Props> = ({
  documentationInformation,
  source = ExternalDataSource.NESO,
}: Props) => {
  const getDocumentationLink = (linkSlug: string): string =>
    `${routeConstants.apiDocs}/endpoint/${linkSlug}`;

  const { data: rawData } = useSwaggerFile();
  const swaggerSpec = JSON.stringify(rawData);
  const apiEndpoints = rawData
    ? transformSwaggerJsonFile(rawData).endpoints
    : [];

  const filteredDocumentationInformation = Object.entries(
    documentationInformation
  ).filter(
    (locationAndlinkSlugs): locationAndlinkSlugs is [string, string[]] =>
      locationAndlinkSlugs[1] && locationAndlinkSlugs[1].length > 0
  );

  return (
    <AdditionalInfo>
      <FullWidthSection className="is-grey-section">
        <ContainerDiv className="container">
          <div className="columns">
            <div className="column no-grow">
              <StyledTitle className="no-wrap">
                Additional information
              </StyledTitle>
              <StyledInfo>Source: {source}</StyledInfo>
            </div>
            <div className="column">
              <StyledTitle>API documentation</StyledTitle>
              {filteredDocumentationInformation.map(([location, linkSlugs]) => (
                <div key={location}>
                  <StyledInfo>
                    The API endpoints used for the {location} on this page are:
                  </StyledInfo>
                  {linkSlugs.map((linkSlug) => (
                    <SingleRowContainer key={`${linkSlug}-${location}`}>
                      <SwaggerSingleRow
                        swaggerSpec={swaggerSpec}
                        endpointData={getEndpointDataByPath(
                          apiEndpoints,
                          `/endpoint/${linkSlug}`
                        )}
                      />
                      <Link href={getDocumentationLink(linkSlug)}>
                        View in API documentation
                      </Link>
                    </SingleRowContainer>
                  ))}
                </div>
              ))}
              <ViewAllApiDocumentationButton href="/api-documentation">
                View All API Documentation
              </ViewAllApiDocumentationButton>
            </div>
          </div>
        </ContainerDiv>
      </FullWidthSection>
    </AdditionalInfo>
  );
};

export default AdditionalInfoSection;

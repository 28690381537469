import Button from "components/components/Button/Button";
import styled from "styled-components";
import colours from "styles/colours";
import { resetButtonStyling } from "styles/commonStyles";

export const StyledFooter = styled.footer`
  background-color: ${colours.elexonBlue};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  *,
  *:hover {
    color: ${colours.white};
  }

  @media screen and (max-width: 1279px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
`;

export const FooterLink = styled.a`
  ${resetButtonStyling} /* allows us to render this as a button (e.g. for the contact modal) while keeping the same styling */

  :hover {
    text-decoration: underline;
  }
`;

export const StyledButton = styled(Button)`
  :focus-visible {
    border: 1px solid transparent;
  }
`;

export const Copyright = styled.div`
  display: flex;
  justify-content: center;
`;

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Icon from "components/components/Icon/Icon";
import { IconSize } from "components/components/Icon/IconWrapper";
import SystemSellBuyPricesCharts from "components/dataVisualization/SystemSellBuyPrices/SystemSellBuyPricesCharts/SystemSellBuyPricesCharts";
import { SettlementMessageModelTransformed } from "models/detailedSystemPrices/settlementModels";
import DateFilterModel from "models/filterModels/dateFilterModel";
import { SystemPriceModel } from "models/systemSellAndBuyPrices/systemSellAndBuyPricesModel";
import React from "react";
import colours from "styles/colours";
import fonts from "styles/fonts";
import { DateFormat, formatDateTimeString } from "utils/dateHelpers";
import routeConstants from "utils/routeConstants";

import { filterAndSortSettlementMessagesToShow } from "./settlementMessagesHelper";
import {
  DateFilterText,
  HorizontalLayout,
  SettlementMessageListItem,
  SettlementMessageList,
  TextLinkWithBottomMargin,
} from "./styles";

interface Props {
  settlementMessages: SettlementMessageModelTransformed[];
  systemPrices: SystemPriceModel[];
  systemPricesDateFilter: DateFilterModel;
}

const SystemSellBuyDataContent: React.FC<Props> = ({
  settlementMessages,
  systemPrices,
  systemPricesDateFilter,
}) => {
  const settlementMessagesToShow = filterAndSortSettlementMessagesToShow(
    settlementMessages,
    systemPrices
  );
  return (
    <>
      {!!settlementMessagesToShow.length && (
        <>
          <SettlementMessageList>
            {settlementMessagesToShow.map((message) => (
              <SettlementMessageListItem
                key={`${message.settlementDate}-${message.settlementPeriod}-${message.messageText}`}
                data-test-id="settlement-message"
              >
                <HorizontalLayout>
                  <Icon
                    iconName={faExclamationTriangle}
                    size={IconSize.medium}
                    colour={colours.elexonOrange}
                  />
                  <h3>Settlement period {message.settlementPeriod}</h3>
                  <span>
                    {formatDateTimeString(
                      message.messageReceivedDateTime,
                      DateFormat.DateTime
                    )}
                  </span>
                </HorizontalLayout>
                <p>{message.messageText}</p>
              </SettlementMessageListItem>
            ))}
          </SettlementMessageList>
          <TextLinkWithBottomMargin to={routeConstants.settlementMessages}>
            See all settlement messages
          </TextLinkWithBottomMargin>
        </>
      )}
      <DateFilterText data-test-id="date-filter-text">
        <span>
          {formatDateTimeString(
            systemPricesDateFilter.startDate,
            DateFormat.DateTimeWithHyphen
          )}{" "}
          UTC
        </span>
        to
        <span>
          {formatDateTimeString(
            systemPricesDateFilter.endDate,
            DateFormat.DateTimeWithHyphen
          )}{" "}
          UTC
        </span>
      </DateFilterText>
      {/* Note: heading size to match the other headings on the page */}
      <SystemSellBuyPricesCharts
        systemPrices={systemPrices}
        headingsAs="h3"
        headingsSizeOverride={fonts.mediumBigger}
      />
    </>
  );
};

export default SystemSellBuyDataContent;

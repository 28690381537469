import RemitItem from "components/homepage/Remit/RemitItem/RemitItem";
import { NoContentText } from "components/homepage/style";
import TextLink from "components/shared/TextLink/TextLink";
import RemitMessageDetailsModelTransformed from "models/remit/remitMessageDetailsModelTransformed";
import React from "react";
import routeConstants from "utils/routeConstants";

import { GridLayoutList } from "./style";

interface Props {
  remitMessages: RemitMessageDetailsModelTransformed[];
}

const maxNumberOfMessagesToShow = 9;

const messageIsCurrent = (
  message: RemitMessageDetailsModelTransformed
): boolean => {
  const now = Date.now();
  const startTime = message.eventStartTime.getTime();

  if (!message.eventEndTime) {
    return startTime <= now;
  }

  const endTime = message.eventEndTime.getTime();
  return startTime <= now && now <= endTime;
};

const compareByMessagePublishTimeDesc = (
  a: RemitMessageDetailsModelTransformed,
  b: RemitMessageDetailsModelTransformed
): number => b.publishTime.getTime() - a.publishTime.getTime();

const filterLatestRevisions = (
  messages: RemitMessageDetailsModelTransformed[]
): RemitMessageDetailsModelTransformed[] => {
  const messagesByMrid = new Map<string, RemitMessageDetailsModelTransformed>();

  messages.forEach((message) => {
    const existingMessage = messagesByMrid.get(message.mrid);
    if (
      !existingMessage ||
      message.revisionNumber > existingMessage.revisionNumber
    ) {
      messagesByMrid.set(message.mrid, message);
    }
  });

  return Array.from(messagesByMrid.values());
};

const RemitContent: React.FC<Props> = ({ remitMessages }: Props) => {
  const inActionMessages = filterLatestRevisions(remitMessages)
    .filter(messageIsCurrent)
    .sort(compareByMessagePublishTimeDesc);

  const messageNumberOverThreshold =
    inActionMessages.length > maxNumberOfMessagesToShow;

  const messagesToShow = inActionMessages.slice(0, maxNumberOfMessagesToShow);

  if (messagesToShow.length === 0) {
    return (
      <NoContentText>
        No new unplanned unavailability messages at this time
      </NoContentText>
    );
  }
  return (
    <>
      <p>Click on an item to see the message details.</p>
      <GridLayoutList data-test-id="remit-list">
        {messagesToShow.map((message) => (
          <RemitItem
            key={message.id}
            mrid={message.mrid}
            messageId={message.id}
            assetId={message.assetId}
            eventStatus={message.eventStatus}
            normalCapacity={message.normalCapacity ?? 0}
            availableCapacity={message.availableCapacity ?? 0}
            fuelType={message.fuelType}
            unavailableCapacity={message.unavailableCapacity ?? 0}
            eventStartTime={message.eventStartTime}
            eventEndTime={message.eventEndTime}
            publishTime={message.publishTime}
          />
        ))}
      </GridLayoutList>
      {messageNumberOverThreshold && (
        <p>
          There are more messages published in the last 12 hours.{" "}
          <TextLink to={routeConstants.remit}>See full coverage.</TextLink>
        </p>
      )}
    </>
  );
};

export default RemitContent;

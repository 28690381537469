import { Link } from "react-router-dom";
import styled from "styled-components";
import colours from "styles/colours";
import { resetButtonStyling } from "styles/commonStyles";

/**
 * A link (using Link from react-router by default) that renders as an underlined blue link.
 *
 * This can also be used with the polymorphic `as` prop to render as a button, e.g.:
 * <TextLink as="button" onClick={handleClick}>Click me</TextLink>
 *
 * Note that this supercedes the existing TextLink and TextLinkWithQueryParams components in
 * components/components. We should use this going forwards (as it is more flexible) and move
 * away from the those.
 */
const StyledLink = styled(Link)`
  ${resetButtonStyling} /* allows us to support as="button" while keeping the same styling */

  text-decoration: underline;
  color: ${colours.linkColor};

  &:hover {
    color: ${colours.linkHoverColor};
  }
`;

export default StyledLink;

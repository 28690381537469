import styled from "styled-components";

export const FaqContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const ExpanderSection = styled.div`
  padding-right: 3em;
  padding-bottom: 3em;
`;
